import React from "react"
import Container from "react-bootstrap/Container"
import { useTranslation } from "react-i18next"
import { graphql } from "gatsby"

import sanitizeHtml from "sanitize-html"

/* Images */
import NavBarLogo from "../images/logo.svg"

/* Components */
import Wrapper from "../components/wrapper/wrapper"
import SEO from "../components/seo/seo"
import NavBar from "../components/navbar/navbar"
import Main from "../components/main/main"
import PostContent from "../components/post-content/post-content"
import Footer from "../components/footer/footer"

export default function Privacy({ data }) {
  const { t } = useTranslation()

  const PageTitle = t("pages.privacy.title")

  const PageContent = { __html: sanitizeHtml(data.markdownRemark.html) }

  return (
    <Wrapper>
      <SEO title={PageTitle} />
      <NavBar classVariant="navBarDefault" logo={NavBarLogo} />
      <Main>
        <Container>
          <PostContent className="col-md-12">
            <div dangerouslySetInnerHTML={PageContent}></div>
          </PostContent>
        </Container>
      </Main>
      <Footer />
    </Wrapper>
  )
}

export const pageQuery = graphql`
  query PrivacyQuery($mdPath: String!) {
    markdownRemark(fileAbsolutePath: { regex: $mdPath }) {
      html
    }
  }
`
